import React from "react";
import MyRouter from "routers/index";

import { getToken } from "api/amadeus.api";
import { setToken } from "state/slice";
import { useAppDispatch, useAppSelector } from "state/hooks";

function App() {
  const amadeusToken = useAppSelector((state) => state.amadeusToken.value);
  const dispatch = useAppDispatch();
  const requestAmadeusToken = async () => {
    try {
      const res: any = await getToken();
      dispatch(setToken(res.data.access_token));
    } catch (error) {
      console.log(error);
    }
  };

  if (!amadeusToken) {
    requestAmadeusToken();
  }

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
