import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import { FocusedInputShape } from "react-dates";
//import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import { FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import moment from "moment";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useAppSelector, useAppDispatch } from "state/hooks";
import ApiService from "api/api.service";
import { getCitiesAndAirport } from "api/amadeus.api";
import { useSearchParams } from "react-router-dom";
import { setOriginCitiesStore, setDestinationCitiesStore } from "state/cities";
import FlightDatesRangeInput from "./FlightDatesRangeInput";
export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface FlightSearchFormProps {
  haveDefaultValue?: boolean;
}

const flightClass = [
  {
    value: "ECONOMY",
    name: "Economy",
  },
  {
    value: "PREMIUM_ECONOMY",
    name: "Premium Economy",
  },
  {
    value: "BUSINESS",
    name: "Business",
  },
  {
    value: "FIRST",
    name: "First Class",
  },
];

const FlightSearchForm: FC<FlightSearchFormProps> = ({ haveDefaultValue }) => {
  const [searchParams] = useSearchParams();
  const fromParams: string = searchParams.get("from") as string;
  const toParams: string = searchParams.get("to") as string;
  const startDateParams: string = searchParams.get("startdate") as string;
  const endDateParams: string = searchParams.get("enddate") as string;
  const travelClass: string = searchParams.get("travelClass") as string;
  const adultsParams: string = searchParams.get("adults") as string;

  const dispatch = useAppDispatch();
  const originCites: any = useAppSelector((state) => state.cities.originCities);
  const destinationCities: any = useAppSelector(
    (state) => state.cities.destinationCities
  );

  const amadeusToken = useAppSelector((state) => state.amadeusToken.value);

  const requestCities = async (type: "from" | "to", searchKey: string) => {
    if (amadeusToken) {
      ApiService.setHeader(amadeusToken);
      if (type === "from") {
        const orgin_res: any = await getCitiesAndAirport("CITY", searchKey);
        dispatch(setOriginCitiesStore(orgin_res.data.data));
      } else if (type === "to") {
        const des_res: any = await getCitiesAndAirport("CITY", searchKey);
        dispatch(setDestinationCitiesStore(des_res.data.data));
      }
    }
  };

  const defaultFormData = {
    fromParams: "",
    toParams: "",
    startDate: moment(),
    endDate: moment(),
    travelClass: "ECONOMY",
  };

  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate:
      startDateParams === undefined ? moment(startDateParams) : moment(),
    endDate: null,
  });

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [pickUpInputValue, setPickUpInputValue] = useState<string>("");
  const [dropOffInputValue, setDropOffInputValue] = useState<string>("");

  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | "dropOffInput" | null
  >(null);
  const [dropOffLocationType, setDropOffLocationType] = useState<
    "roundTrip" | "oneWay" | ""
  >("roundTrip");
  const [guests, setGuests] = useState(1);
  const [flightClassState, setFlightClassState] = useState(
    travelClass ? travelClass : "ECONOMY"
  );
  const [flightClassName, setFlightClassName] = useState(
    travelClass ? travelClass : "Economy"
  );
  useEffect(() => {
    if (pickUpInputValue) {
      requestCities("from", pickUpInputValue);
    }
  }, [pickUpInputValue]);

  useEffect(() => {
    if (dropOffInputValue) {
      requestCities("to", dropOffInputValue);
    }
  }, [dropOffInputValue]);

  // USER EFFECT
  useEffect(() => {
    // if (haveDefaultValue) {
    //   setDateRangeValue({
    //     startDate: startDateParams === undefined ? moment(startDateParams) : moment(),
    //     endDate: endDateParams === undefined ? moment(endDateParams) : moment().add(4, "days"),
    //   });

    //   setPickUpInputValue(fromParams);
    //   setDropOffInputValue(toParams);
    //   requestCities();
    // }
    // else {
    // requestCities();
    // }

    // setFlightClassState(travelClass ? travelClass : 'ECONOMY');
    if (amadeusToken) {
      // set searchDefaultForm with query
      setPickUpInputValue(fromParams ? fromParams : defaultFormData.fromParams);
      setDropOffInputValue(toParams ? toParams : defaultFormData.toParams);
      // setDateRangeValue({
      //   startDate: startDateParams ? moment(startDateParams) : defaultFormData.startDate,
      //   endDate: endDateParams ? moment(endDateParams) : defaultFormData.endDate
      // });
      setFlightClassState(
        travelClass ? travelClass : defaultFormData.travelClass
      );
      setStartDate(
        startDateParams
          ? startDateParams
          : defaultFormData.startDate.format("YYYY-MM-DD")
      );
      setEndDate(endDateParams ? endDateParams : "");
    }
  }, [amadeusToken]);

  const renderGuest = () => {
    return (
      <div className="">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span>{`${guests} Guest`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                    <div className="relative bg-white dark:bg-neutral-800 p-4">
                      <NcInputNumber
                        onChange={(e) => setGuests(e)}
                        min={1}
                        defaultValue={guests}
                        max={20}
                      />
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="">
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span>{`${flightClassName}`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                    <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                      {flightClass.map((item) => (
                        <span
                          key={item.value}
                          onClick={(e) => {
                            e.preventDefault();
                            setFlightClassName(item.name);
                            setFlightClassState(item.value);
                            close();
                          }}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <p className="text-sm font-medium ">{item.name}</p>
                        </span>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === "roundTrip"
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType("roundTrip")}
        >
          Round-trip
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === "oneWay"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType("oneWay")}
        >
          One-way
        </div>
        <div className=" mr-2 my-1 sm:mr-4 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="w-full">
        <form className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
          {renderRadioBtn()}
          <div className="flex flex-1 rounded-full">
            <div className="relative flex flex-1">
              <LocationInput
                cities={originCites}
                defaultValue={pickUpInputValue!}
                onChange={(e) => setPickUpInputValue(e)}
                onInputDone={(item) => {
                  setFieldFocused("dropOffInput");
                  setPickUpInputValue(item);
                }}
                placeHolder="Flying from"
                desc="Where do you want to fly from?"
              />
              <LocationInput
                cities={destinationCities}
                defaultValue={dropOffInputValue!}
                onChange={(e) => setDropOffInputValue(e)}
                onInputDone={(item) => {
                  setFieldFocused("startDate");
                  setDropOffInputValue(item);
                }}
                placeHolder="Flying to"
                desc="Where you want to fly to?"
                autoFocus={fieldFocused === "dropOffInput"}
              />
            </div>
            <FlightDatesRangeInput
              defaultDateValue={dateRangeValue}
              defaultFocus={
                fieldFocused === "dropOffInput" ? null : fieldFocused
              }
              oneWay={dropOffLocationType === "oneWay" ? true : false}
              onFocusChange={(focus) => setFieldFocused(focus)}
              onChange={(data) => {
                setDateRangeValue(data.stateDate);
                setStartDate(data.stateDate.startDate?.format("YYYY-MM-DD")!);
                setEndDate(data.stateDate.endDate?.format("YYYY-MM-DD")!);
              }}
              className="flex-1"
              buttonSubmitHref={`/listing-flights?from=${pickUpInputValue}&to=${dropOffInputValue}&startdate=${startDate}&enddate=${endDate}&travelClass=${flightClassState}&adults=${guests}`}
            />
          </div>
        </form>
      </div>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
