import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { redirect } from "react-router-dom";

import {
  getAuth,
  AuthError,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  CustomParameters,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";

const firebaseConfig = {
  //apiKey: process.env.REACT_APP_FIRE_API_KEY,
  //authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  //databaseURL: process.env.REACT_APP_FIRE_DB_URL,
  //projectId: process.env.REACT_APP_FIRE_PROJ_ID,
  //storageBucket: process.env.REACT_APP_FIRE_STORE_BUCKET,
  //messagingSenderId: process.env.REACT_APP_FIRE_SENDER_ID,
  //appId: process.env.REACT_APP_FIRE_APP_ID,
  apiKey: "AIzaSyClqNbDcfF5140F8rPUohA7wPIwjCO9-sI",
  authDomain: "travel-portal-e3fb1.firebaseapp.com",
  databaseURL:
    "https://travel-portal-e3fb1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "travel-portal-e3fb1",
  storageBucket: "travel-portal-e3fb1.appspot.com",
  messagingSenderId: "283207517726",
  appId: "1:283207517726:web:c485d25360dcd96cb6d00f",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);

export type AuthActionHook<M> = [
  M,
  UserCredential | undefined,
  boolean,
  AuthError | undefined
];

export type SignInWithPopupHook = AuthActionHook<
  (
    scopes?: string[],
    customOAuthParameters?: CustomParameters
  ) => Promise<UserCredential | undefined>
>;

export const currentUser = async () => {
  try {
    const user = await auth.currentUser;
    return user;
  } catch (err: any) {
    console.error(err);
  }
};

export const signOut = async () => {
  try {
    await auth.signOut();
    return window.location.reload();
  } catch (err) {
    console.error(err);
  }
};

export const addNewsletterEmail = (email: string) => {
  const emailColRef = collection(db, "newsletterEmails");
  return addDoc(emailColRef, {
    created: serverTimestamp(),
    email: email,
  });
};

export const addContactMessage = (
  firstName: string,
  lastName: string,
  email: string,
  message: string
) => {
  const emailColRef = collection(db, "contact-message");
  return addDoc(emailColRef, {
    created: serverTimestamp(),
    firstName: firstName,
    lastName: lastName,
    email: email,
    message: message,
  });
};

export const addUser = (
  email: any,
  fullName: any,
  photoUrl: any,
  verified: any
) => {
  const userColRef = collection(db, "users");
  return addDoc(userColRef, {
    created: serverTimestamp(),
    email: email,
    fullName: fullName,
    photoUrl: photoUrl,
    emailVerified: verified,
  });
};

export const signInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const user = await firebaseSignInWithEmailAndPassword(auth, email, password);
  return user;
};

export const signUpWithEmailAndPassword = async (
  fullName: any,
  email: any,
  password: any
) => {
  const verified = false;
  const photoUrl = "";
  const user = await firebaseCreateUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  addUser(email, fullName, photoUrl, verified);
  return user;
};

export const useSignInWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider();
    const user = await signInWithPopup(auth, provider);
    return user;
  } catch (err) {
    console.error(err);
  }
};

export const useSignInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const user = await signInWithPopup(auth, provider);
    if (user) {
      const currentUser = auth.currentUser;
      addUser(
        currentUser?.email,
        currentUser?.displayName,
        currentUser?.photoURL,
        currentUser?.emailVerified
      );
    }
    return redirect("/");
  } catch (err) {
    console.error(err);
  }
};
