import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  value: '',
};

const slice = createSlice({
  name: 'amadeusToken',
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {    
    // The `PayloadAction` type allows us to declare the contents of `action.payload`
    setToken: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
  },
});

export default slice.reducer;

export const { setToken } = slice.actions;
