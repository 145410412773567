import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  data: {
    type: "",
    message: "",
  },
};

const alertStore = createSlice({
  name: "alert",
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    // The `PayloadAction` type allows us to declare the contents of `action.payload`
    setAlertIsOpen: (state, action: PayloadAction<any>) => {
      state.isOpen = action.payload;
    },

    setAlertData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export default alertStore.reducer;

export const { setAlertIsOpen, setAlertData } = alertStore.actions;
