import React, { FC, useState } from "react";
import calcDateRangeTime from "utils/calcDateRangeTime";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "state/hooks";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { setAlertData, setAlertIsOpen } from "state/alert";
import { addBookingItem } from "state/checkout";

export interface FlightCardProps {
  className?: string;
  data: any;
  orginCity: any;
  airLineName?: string;
}

const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  orginCity,
  airLineName,
}) => {
  const dispatch = useAppDispatch();
  const busNameList = useAppSelector((state) => state.busNameReducer.value);

  const getBusName = (itaCode: string) => {
    let index = busNameList.findIndex((x: any) => x.itaCode === itaCode);
    if (index > -1) {
      return busNameList[index].busName?.toLowerCase();
    } else {
      return "";
    }
  };

  // const rquestBooking = async () => {
  //   try {
  //     const res = await booking(data);
  //     console.log('booking data', res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }

  // }

  const totalRangeTime = (): string => {
    return (
      data.itineraries[0].segments[0].departure.at.slice(11).substr(0, 5) +
      " - " +
      data.itineraries[0].segments[
        data.itineraries[0].segments.length - 1
      ].arrival.at
        .slice(11)
        .substr(0, 5)
    );
  };

  const totalItaCode = (): string => {
    let res = "";
    for (let i = 0; i < data.itineraries[0].segments.length; i++) {
      res = res + data.itineraries[0].segments[i].departure.iataCode + "-";
      if (i === data.itineraries[0].segments.length - 1) {
        res = res + data.itineraries[0].segments[i].arrival.iataCode;
      }
    }
    return res;
  };

  const totalStop = (): number => {
    return data.itineraries[0].segments.length - 1;
  };

  const totalTime = (): string => {
    let totalMins = moment(
      data.itineraries[0].segments[data.itineraries[0].segments.length - 1]
        .arrival.at
    ).diff(moment(data.itineraries[0].segments[0].departure.at), "minutes");
    return calcDateRangeTime(totalMins);
  };

  const [isOpen, setIsOpen] = useState(false);

  const getBussiness = (segmentId: string): string => {
    let index = data.travelerPricings[0].fareDetailsBySegment.findIndex(
      (x: any) => x.segmentId === segmentId
    );
    if (index > -1) {
      return data.travelerPricings[0].fareDetailsBySegment[index].cabin;
    }
    return "";
  };

  const renderDetailTop = (item: any) => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img
              src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${item.carrierCode}.svg`}
              className="w-10"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {moment(item.departure.at).format("dddd, MMMM Do, h:mm A")}
                </span>
                <span className="capitalize font-semibold">
                  {getBusName(item.departure.iataCode)} Airport (
                  {item.departure.iataCode})
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {moment(item.arrival.at).format("dddd, MMMM Do, h:mm A")}
                </span>
                <span className="capitalize font-semibold">
                  {getBusName(item.arrival.iataCode)} Airport (
                  {item.arrival.iataCode})
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li>
              Trip time:{" "}
              {calcDateRangeTime(
                moment(item.arrival.at).diff(
                  moment(item.departure.at),
                  "minutes"
                )
              )}
            </li>
            <li>
              {item.carrierCode} · {getBussiness(item.id)} · {item.number}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderTransitTime = () => {
    if (data.itineraries[0].segments.length === 2) {
      return (
        <div className="my-7 md:my-10 space-y-5 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            Transit time:{" "}
            {calcDateRangeTime(
              moment(
                data.itineraries[0].segments[
                  data.itineraries[0].segments.length - 1
                ].departure.at
              ).diff(
                moment(data.itineraries[0].segments[0].arrival.at),
                "minutes"
              )
            )}{" "}
            - {orginCity} ({data.itineraries[0].segments[0].arrival.iataCode})
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
        </div>
      );
    }
    return "";
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop(data.itineraries[0].segments[0])}
        {renderTransitTime()}
        {data.itineraries[0].segments.length === 2
          ? renderDetailTop(data.itineraries[0].segments[1])
          : ""}
      </div>
    );
  };

  const requestBook = () => {
    dispatch(
      addBookingItem({
        id: data.id,
        orginCity,
        totalRangeTime: totalRangeTime(),
        totalItaCode: totalItaCode(),
        totalTime: totalTime(),
        totalPrice: data.price.total,
        amount: 1,
        currency: data.price.currency,
      })
    );

    dispatch(setAlertIsOpen(true));
    dispatch(
      setAlertData({
        type: "bg-green-600",
        message: `${orginCity} Air, ${totalItaCode()} added to cart`,
      })
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        {/* <a href="##" className="absolute inset-0" /> */}

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img
              src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${data.itineraries[0].segments[0].carrierCode}.svg`}
              className="w-10"
              alt=""
            />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>11:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  HND
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>20:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  SIN
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>7h 45m</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">{totalRangeTime()}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {airLineName}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">{totalItaCode()}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {totalTime()}
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">{totalStop()} stop</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {totalTime()} {data.itineraries[0].segments[0].arrival.iataCode}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {data.price.total} {data.price.currency}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              round-trip
            </div>
          </div>

          {/* TYPE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <ButtonPrimary onClick={requestBook}>Book</ButtonPrimary>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCard;
