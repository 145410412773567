import React from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header/Header";
import { useLocation } from "react-router-dom";
import { PathName } from "routers/types";

export type SiteHeaders = "Header 1";

const PAGES_HIDE_HEADER_BORDER: PathName[] = [
  "/listing-car-detail",
  "/listing-experiences-detail",
  "/listing-stay-detail",
];

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
  const location = useLocation();

  let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";
  if (PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
    headerClassName = isTopOfPage
      ? ""
      : "shadow-sm dark:border-b dark:border-neutral-700";
  }
  return (
    <>
      <Helmet>
        <title>Ciao Trip</title>
      </Helmet>
      <Header className={headerClassName} navType="MainNav1" />;
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
