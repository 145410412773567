import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import * as FirestoreService from "services/firebase";
export interface SectionSubscribe2Props {
  className?: string;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [emailUploaded, setEmailUploaded] = useState(false);
  const initialValues = {
    email: "",
  };

  const onSubmit = (values: any) => {
    FirestoreService.addNewsletterEmail(values.email)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setEmailUploaded(true);
        formik.resetForm();
      })
      .catch((error) => console.log(error));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Read and share new perspectives on just about any topic. Everyone’s
          welcome.
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get latest updates
            </span>
          </li>
          {/*  <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get premium magazines
            </span>
          </li>
          */}
        </ul>

        <form
          className="mt-10 relative max-w-sm"
          onSubmit={formik.handleSubmit}
        >
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            name="email"
            rounded="rounded-full"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            {!emailUploaded && <i className="las la-arrow-right text-xl"></i>}
            {emailUploaded && <i className="las la-check"></i>}
          </ButtonCircle>
          {formik.touched.email && Boolean(formik.errors.email)}
          {formik.touched.email && formik.errors.email}
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
