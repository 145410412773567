import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  from: "",
  to: "",
  pickUpDate: "",
  dropOffDate: "",
};

const flightStore = createSlice({
  name: "flightSearch",
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    // The `PayloadAction` type allows us to declare the contents of `action.payload`
    setFlightsSearch: (state, action: PayloadAction<any>) => {
      state = action.payload;
    },
  },
});

export default flightStore.reducer;

export const { setFlightsSearch } = flightStore.actions;
