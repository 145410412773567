import { configureStore } from "@reduxjs/toolkit";
import tokenReducer from "./slice";
import flightsReducer from "./flightSearch";
import citiesReducer from "./cities";
import checkOutReducer from "./checkout";
import alertReducer from "./alert";
import apiLoadingReducer from "./apiLoading";
import busNameReducer from "./busName";

const store = configureStore({
  reducer: {
    amadeusToken: tokenReducer,
    flightSearch: flightsReducer,
    cities: citiesReducer,
    checkOutReducer: checkOutReducer,
    alertReducer: alertReducer,
    apiLoadingReducer: apiLoadingReducer,
    busNameReducer: busNameReducer,
  },
});

export default store;
