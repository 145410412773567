import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

const checkOutStore = createSlice({
  name: "checkout",
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    // The `PayloadAction` type allows us to declare the contents of `action.payload`
    addBookingItem: (state: any, action: PayloadAction<any>) => {
      const existingItem = state.value.find(
        (i: any) => i.id === action.payload.id
      );
      if (existingItem) {
        existingItem.amount++;
      } else {
        state.value.push(action.payload);
      }
    },

    removeBookingItem: (state: any, action: PayloadAction<any>) => {
      const index = state.value.findIndex((i: any) => i.id === action.payload);
      if (index > -1) {
        state.value.splice(index, 1);
      }
    },

    removeAllBookingItems: (state: any) => {
      state.value = [];
    },

    incrementAmountBookingItem: (state: any, action: PayloadAction<any>) => {
      const index = state.value.findIndex((i: any) => i.id === action.payload);
      if (index > -1) {
        state.value[index].amount++;
      }
    },

    decrementAmountBookingItem: (state: any, action: PayloadAction<any>) => {
      const index = state.value.findIndex((i: any) => i.id === action.payload);
      if (index > -1) {
        state.value[index].amount--;
      }
    },
  },
});

export default checkOutStore.reducer;

export const {
  addBookingItem,
  removeBookingItem,
  incrementAmountBookingItem,
  decrementAmountBookingItem,
  removeAllBookingItems,
} = checkOutStore.actions;
