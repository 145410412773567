import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const apiLoadingStore = createSlice({
  name: "apiloading",
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    // The `PayloadAction` type allows us to declare the contents of `action.payload`
    setApiLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default apiLoadingStore.reducer;

export const { setApiLoading } = apiLoadingStore.actions;
