import ApiService from "./api.service";

const getToken = async () => {
  const data = new URLSearchParams({
    grant_type: "client_credentials",
    // client_id: `${process.env.REACT_APP_AMADEUS_API}`,
    //client_secret: `${process.env.REACT_APP_AMADEUS_SECRET}`,
    client_id: "AWKrFGaihEu4W6MiwYQC2plCYBFXkCes",
    client_secret: "owxopaNjUh8Q67A3",
  });
  return await ApiService.post("v1/security/oauth2/token", data);
};

const getFlightOffers = async (
  originLocationCode,
  destinationLocationCode,
  departureDate,
  returnDate,
  travelClass,
  max = 40,
  adults = 1
) => {
  let url = returnDate
    ? `v2/shopping/flight-offers?originLocationCode=${originLocationCode}&destinationLocationCode=${destinationLocationCode}&departureDate=${departureDate}&adults=${adults}&returnDate=${returnDate}&travelClass=${travelClass}&max=${max}`
    : `v2/shopping/flight-offers?originLocationCode=${originLocationCode}&destinationLocationCode=${destinationLocationCode}&departureDate=${departureDate}&adults=${adults}&travelClass=${travelClass}&max=${max}`;
  console.log("getFlightOffers API Call", {
    originLocationCode,
    destinationLocationCode,
    departureDate,
    returnDate,
    travelClass,
    adults,
  });
  return await ApiService.get(url);
};

const getCitiesAndAirport = async (subType, keyword) => {
  let url = "";
  if (subType === "CITY") {
    url = `v1/reference-data/locations?subType=${subType}&keyword=${keyword}`;
  } else if (subType === "AIRPORT") {
    url = `v1/reference-data/locations?subType=${subType}&keyword=${keyword}&page[1]`;
  }
  // console.log('getCiteis API call', url);
  return await ApiService.get(url);
};

const getAirLine = async (itaCode) => {
  let url = `v1/reference-data/airlines?airlineCodes=${itaCode}`;
  return await ApiService.get(url);
};

const booking = async (flightOffer) => {
  let url = "v1//booking/flight-orders";
  return await ApiService.post(url, flightOffer);
};

const experience = async (latitude, longitude, radius = 5) => {
  let url = `v1/shopping/activities?latitude=${latitude}&longitude=${longitude}&radius=${radius}`;
  return await ApiService.post(url);
};

export {
  getToken,
  getFlightOffers,
  getCitiesAndAirport,
  getAirLine,
  booking,
  experience,
};
