import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC, useState, useEffect, useCallback } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import {
  getFlightOffers,
  getAirLine,
  getCitiesAndAirport,
  experience,
} from "api/amadeus.api";
import { useAppDispatch, useAppSelector } from "state/hooks";
import ApiService from "api/api.service";
import { useSearchParams } from "react-router-dom";
import { setApiLoading } from "state/apiLoading";
import { addBussName } from "state/busName";

export interface ListingFlightsPageProps {
  className?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Enjoy the Beauty of Brazil ",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "2",
    href: "#",
    name: "Enjoy the Beauty of Paris",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "3",
    href: "#",
    name: "Enjoy the Beauty of Bangkok",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "5",
    href: "#",
    name: "Enjoy the Beauty of Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "4",
    href: "#",
    name: "Enjoy the Beauty of Seoul",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
];

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  const dispatch = useAppDispatch();

  // get searchData from query
  const [searchParams] = useSearchParams();
  const fromParams: string = searchParams.get("from") as string;
  const toParams: string = searchParams.get("to") as string;
  const startDateParams: string = searchParams.get("startdate") as string;
  const endDateParams: string = searchParams.get("enddate") as string;
  const travelClass: string = searchParams.get("travelClass") as string;
  const adultsParams: string = searchParams.get("adults") as string;

  const [flightsOffersCount, setFlightsOffersCount] = useState(0);
  const [flightsOffers, setFlightsOffers] = useState<any>([]);
  const [destinationLat, setDestinationLat] = useState("");
  const [destinationLong, setDestinationLong] = useState("");

  //store
  const amadeusToken = useAppSelector((state) => state.amadeusToken.value);
  const originCitiesStore: any = useAppSelector(
    (state) => state.cities.originCities
  );
  const destinationCitiesStore: any = useAppSelector(
    (state) => state.cities.destinationCities
  );
  const busNameStore: any = useAppSelector(
    (state) => state.busNameReducer.value
  );

  //useState
  const [maxCount, setMaxCount] = useState(10);
  const [isRefresh, setIsRefresh] = useState(false);
  const [airLineName, setAirLineName] = useState("");

  // useCallBack
  const showMore = useCallback(() => {
    if (maxCount === 250) {
      return;
    }
    setMaxCount(maxCount + 10);
  }, [maxCount]);

  // Api call
  const experienceOffers = async () => {
    if (amadeusToken) {
      dispatch(setApiLoading(true));
      try {
        ApiService.setHeader(amadeusToken);

        const res: any = await experience(destinationLat, destinationLong);
        const newData = res.data.data;
        console.log("<<<<<<<<<<<<<", res);
        dispatch(setApiLoading(false));
      } catch (error) {
        dispatch(setApiLoading(false));
        console.log("call requestFlightOffers", error);
      }
    }
  };

  // Api call
  const requestFlightOffers = async (
    originLocationCode: string,
    destinationLocationCode: string,
    startDate: string,
    endDate: string,
    travelClass: string,
    maxCount: number
  ) => {
    if (amadeusToken) {
      dispatch(setApiLoading(true));
      try {
        ApiService.setHeader(amadeusToken);

        const res: any = await getFlightOffers(
          originLocationCode,
          destinationLocationCode,
          startDate,
          endDate,
          travelClass,
          maxCount
        );
        const newData = res.data.data;
        setFlightsOffersCount(res.data.data.length);

        const itaCodeList: any[] = getItaCodeList(res.data.data);
        for (let i = 0; i < itaCodeList.length; i++) {
          const res = busNameStore.find(
            (x: any) => x.itaCode === itaCodeList[i]
          );
          if (res) continue;

          const busName: any = await getAirPortName(itaCodeList[i]);
          await sleep(1000);
          const newBussNameObj = {
            itaCode: itaCodeList[i],
            busName: busName,
          };
          dispatch(addBussName(newBussNameObj));
        }

        const _ = await getAirLine(
          newData[0].itineraries[0].segments[0].carrierCode
        );
        setAirLineName(_.data.data[0].businessName);
        setFlightsOffers(res.data.data);

        dispatch(setApiLoading(false));
      } catch (error) {
        dispatch(setApiLoading(false));
        console.log("call requestFlightOffers", error);
      }
    }
  };

  // useEffect
  useEffect(() => {
    if (isRefresh) {
      if (originCitiesStore.length > 0 && destinationCitiesStore.length > 0) {
        let originIndex = originCitiesStore.findIndex(
          (x: any) => x.address.cityName === fromParams
        );
        let destinationIndex = destinationCitiesStore.findIndex(
          (x: any) => x.address.cityName === toParams
        );
        let originLocationCode, destinationLocationCode;
        if (originIndex > -1) {
          originLocationCode = originCitiesStore[originIndex].iataCode;
        }
        if (destinationIndex > -1) {
          destinationLocationCode =
            destinationCitiesStore[destinationIndex].iataCode;
        }
        if (originLocationCode && destinationLocationCode) {
          requestFlightOffers(
            originLocationCode,
            destinationLocationCode,
            startDateParams,
            endDateParams,
            travelClass,
            maxCount
          );
        }
        if (destinationLat && destinationLong) {
          experienceOffers();
        }
      }
    }
  }, [
    fromParams,
    toParams,
    startDateParams,
    endDateParams,
    travelClass,
    maxCount,
    isRefresh,
  ]);

  useEffect(() => {
    if (destinationCitiesStore.length > 0) setIsRefresh(true);
  }, [destinationCitiesStore]);

  const getAirPortName = async (itacode: string) => {
    try {
      const res: any = await getCitiesAndAirport("AIRPORT", itacode);
      if (res.data.data.length === 0) {
        return "";
      } else {
        console.log(res);
        setDestinationLat(res.data.data[0].geoCode.latitude);
        setDestinationLong(res.data.data[0].geoCode.longitude);
        return res.data.data[0].name;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getItaCodeList = (flightOffers: any) => {
    let itaCodeList: any[] = [];
    for (let i = 0; i < flightOffers.length; i++) {
      let itemItaCodeList = totalItaCode(flightOffers[i]).split("-");
      for (let j = 0; j < itemItaCodeList.length; j++) {
        const index = itaCodeList.findIndex(
          (x: string) => x === itemItaCodeList[j]
        );
        if (index > -1) {
        } else {
          itaCodeList.push(itemItaCodeList[j]);
        }
      }
    }
    return itaCodeList;
  };

  const totalItaCode = (data: any): string => {
    let res = "";
    for (let i = 0; i < data.itineraries[0].segments.length; i++) {
      res = res + data.itineraries[0].segments[i].departure.iataCode + "-";
      if (i === data.itineraries[0].segments.length - 1) {
        res = res + data.itineraries[0].segments[i].arrival.iataCode;
      }
    }
    return res;
  };

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Cio Trip</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">{flightsOffersCount} flights</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
          orginCity={fromParams}
          desCity={toParams}
        />

        {/* SECTION */}
        <SectionGridFilterCard
          orginCity={fromParams}
          desCity={toParams}
          flightsCount={flightsOffersCount}
          flightsOffers={flightsOffers}
          className="pb-24 lg:pb-28"
          showMore={showMore}
          airLineName={airLineName}
          oneWay={endDateParams ? false : true}
          guestCount={adultsParams}
        />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading={`Experiences in ${toParams}`}
          subHeading="Choose from our experencies and explore"
          categoryCardType="card4"
          itemPerRow={4}
          categories={DEMO_CATS}
          uniqueClassName="ListingFlightsPage-section1"
        />

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default ListingFlightsPage;
