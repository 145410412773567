import React, { FC } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAppSelector } from "state/hooks";

export interface SectionGridFilterCardProps {
  className?: string;
  orginCity?: any;
  desCity?: any;
  flightsCount?: number;
  flightsOffers?: any;
  showMore?: any;
  airLineName?: string;
  oneWay?: boolean;
  guestCount?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  orginCity,
  desCity,
  flightsCount,
  flightsOffers,
  oneWay,
  guestCount,
  showMore,
  airLineName,
}) => {
  const loading = useAppSelector((state) => state.apiLoadingReducer.isLoading);

  const renderFooter = () => {
    if (loading) {
      // return (<ButtonPrimary loading>Show more</ButtonPrimary>)
    } else {
      if (flightsCount === 0) {
        return (
          <span className=" text-dark text-xl font-semibold">
            Can't find flights
          </span>
        );
      } else {
        return <ButtonPrimary onClick={showMore}>Show more</ButtonPrimary>;
      }
    }
  };

  const renderTableLoading = () => {
    if (loading) {
      return (
        <div className="spinner-container">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`${
          orginCity.slice(0, 1) + orginCity.slice(1).toLowerCase()
        } - ${desCity.slice(0, 1) + desCity.slice(1).toLowerCase()}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {flightsCount} flights
            <span className="mx-2">·</span>
            {oneWay ? "One-way" : "Round-trip"}
            <span className="mx-2">·</span>
            {guestCount} person
          </span>
        }
      />
      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl relative min-h-[150px]">
        {renderTableLoading()}
        {flightsOffers?.map((item: any, index: number) => (
          <FlightCard
            key={index}
            data={item}
            orginCity={orginCity}
            airLineName={airLineName}
          />
        ))}
        <div className="flex justify-center items-center">{renderFooter()}</div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
