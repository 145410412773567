import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  value: [] as any,
};

const bussNameStore = createSlice({
  name: "bussName",
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    // The `PayloadAction` type allows us to declare the contents of `action.payload`
    addBussName: (state, action: PayloadAction<any>) => {
      state.value.push(action.payload);
    },
  },
});

export default bussNameStore.reducer;

export const { addBussName } = bussNameStore.actions;
