import React, { FC } from "react";
//import facebookSvg from "images/Facebook.svg";
//import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useSignInWithGoogle,
  signUpWithEmailAndPassword,
} from "services/firebase";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  //{
  //  name: "Continue with Facebook",
  //  href: "#",
  //  icon: facebookSvg,
  //},
  //{
  //  name: "Continue with Twitter",
  //  href: "#",
  //  icon: twitterSvg,
  //},
  {
    name: "Continue with Google",
    href: useSignInWithGoogle,
    icon: googleSvg,
  },
];

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, "Name too short")
    .max(50, "Name too long")
    .required("Please specify the name"),

  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("No password provided.")
    .min(6, "Password is too short - should be 6 chars minimum."),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    fullName: "",
    password: "",
    passwordConfirmation: "",
  };
  const onSubmit = (values: any) => {
    try {
      signUpWithEmailAndPassword(
        values.fullName,
        values.email,
        values.password
      );
      navigate("/login");
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Ciao Trip</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <button
                key={index}
                onClick={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </button>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={formik.handleSubmit}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Full name*
              </span>
              <Input
                type="text"
                placeholder="full name"
                className="mt-1"
                onChange={formik.handleChange}
                name={"fullName"}
                value={formik.values?.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName}
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address*
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={formik.handleChange}
                name={"email"}
                value={formik.values?.email}
              />
              {formik.touched.email && formik.errors.email}
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password*
              </span>
              <Input
                type="password"
                className="mt-1"
                onChange={formik.handleChange}
                name={"password"}
                value={formik.values?.password}
              />
              {formik.touched.password && formik.errors.password}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Re-enter password*
              </span>
              <Input
                type="password"
                placeholder="retype your password"
                className="mt-1"
                onChange={formik.handleChange}
                name={"passwordConfirmation"}
                value={formik.values?.passwordConfirmation}
              />
              {formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation}
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
