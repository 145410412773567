import Label from "components/Label/Label";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { auth } from "services/firebase";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";

const AccountPass = () => {
  const [email, setEmail] = useState("");
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);
  const actionCodeSettings = {
    url: "http://localhost:3000/login",
  };
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1.5"
              />
            </div>

            <div className="pt-2">
              {sending ? (
                <Label>Sending...</Label>
              ) : (
                <ButtonPrimary
                  onClick={async () => {
                    const success = await sendPasswordResetEmail(
                      email,
                      actionCodeSettings
                    );
                    if (success) {
                      alert("Email sent!");
                    }
                  }}
                >
                  Get link
                </ButtonPrimary>
              )}
              {error && <p>{error.message}</p>}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
